/********** Template CSS **********/
:root {
  --primary: #095589;
  --secondary: #999999;
  --light: #f2f8fe;
  --dark: #111111;
  --background-color: #ffffff;
  --default-color: #444444;
  --accent-color: #c6e9f8;
}

.back-to-top {
  position: fixed;
  display: none;
  right: 30px;
  bottom: 60px;
  z-index: 99;
}
h1 {
  font-family: "Anton SC", sans-serif !important ;
  color: #262524;
  font-size: 35px;
  font-weight: 400;
  letter-spacing: 2px;
}
h2 {
  font-size: 35px;
  font-family: "Anton SC", sans-serif !important ;
  font-weight: 400;
  font-style: normal;
  color: #262524;
  letter-spacing: 2px;
  text-transform: capitalize;
}
h4,
h5,
h6,
.h4,
.h5,
.h6 {
  font-weight: 500 !important;
}

/*** Spinner ***/
#spinner {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-out, visibility 0s linear 0.5s;
  z-index: 99999;
}

#spinner.show {
  transition: opacity 0.5s ease-out, visibility 0s linear 0s;
  visibility: visible;
  opacity: 1;
}

/*** Button ***/
.btn {
  font-weight: 500;
  transition: 0.5s;
}
.btn-theme {
  color: #16d5ff;
  background-color: #ffffff;
}
.btn-theme:hover {
  background-color: #16d5ff;
  color: #f2f8fe;
}

.btn.btn-primary {
  color: #ffffff;
}

.btn-square {
  width: 38px;
  height: 38px;
}

.btn-sm-square {
  width: 32px;
  height: 32px;
}

.btn-lg-square {
  width: 48px;
  height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
}

/*** Navbar ***/
.navbar.sticky-top {
  top: -100px;
  transition: 0.5s;
}

.navbar .navbar-brand {
  height: 95px;
}

.navbar .navbar-nav .nav-link {
  margin-right: 30px;
  padding: 25px 0;
  color: var(--dark);
  font-weight: 700;
  outline: none;
  font-size: 18px;
}

.navbar .navbar-nav .nav-link:hover,
.navbar .navbar-nav .nav-link.active {
  color: var(--primary);
}

.navbar .dropdown-toggle::after {
  border: none;
  content: "\f107";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  vertical-align: middle;
  margin-left: 8px;
}

@media (max-width: 991.98px) {
  .navbar .navbar-nav .nav-link {
    margin-right: 0;
    padding: 10px 0;
  }

  .navbar .navbar-nav {
    border-top: 1px solid #eeeeee;
  }
}

@media (min-width: 992px) {
  .navbar .nav-item .dropdown-menu {
    display: block;
    border: none;
    margin-top: 0;
    top: 150%;
    opacity: 0;
    visibility: hidden;
    transition: 0.5s;
  }

  .navbar .nav-item:hover .dropdown-menu {
    top: 100%;
    visibility: visible;
    transition: 0.5s;
    opacity: 1;
  }
}

.navbar .btn:hover {
  color: #ffffff !important;
  background: var(--primary) !important;
}

/*** Header ***/
.hero-header .breadcrumb-item + .breadcrumb-item::before {
  color: var(--secondary);
}

/*** Service ***/
.service-item {
  position: relative;
  overflow: hidden;
}

.service-item::after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  top: -80px;
  right: -80px;
  border: 80px solid;
  transform: rotate(45deg);
  opacity: 1;
  transition: 0.5s;
}

.service-item.bg-white::after {
  border-color: transparent transparent var(--light) transparent;
}

.service-item.bg-light::after {
  border-color: transparent transparent #ffffff transparent;
}

.service-item:hover::after {
  opacity: 0;
}

.main .heading h1 {
  font-size: 30px;
  font-family: "Anton SC", sans-serif !important ;
  font-weight: 400;
  font-style: normal;
  color: #262524;
  letter-spacing: 1px;
  margin-bottom: 5px;
}

.main .heading span {
  color: red;
}

.main .content h3 {
  color: #262524;
  font-size: 25px;
  font-family: sans-serif !important ;
  font-weight: 600;
  font-style: normal;
}

.main .content p {
  color: black;
  font-size: larger;
}

.analytics.heading h3 {
  font-size: 25px;
  font-family: sans-serif !important ;
  font-weight: 600;
  font-style: normal;
  color: #262524;
  margin-bottom: 5px;
}

.analytics .content .card {
  min-height: 280px;
  border-radius: 6px;
  background: #f0f8fe;
  box-shadow: 5px 5px 10px #a6a5b0, -5px -5px 10px #ffffff;
}
.analytics .content .card p {
  margin-top: 10px;
  line-height: 25px;
  font-weight: 500;
  color: black;
}
.analytics .content .card h5 {
  font-size: 20px;
}

/* SERVICE  button */
.get-p-button {
  --color: #0d4258;
  border-radius: 0.4rem;
  padding: 10px 10px;
  background-color: transparent;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: 0.5s;
  font-weight: 400;
  font-size: 16px;
  background: whitesmoke;
  border: 1px solid;
  font-family: inherit;
  text-transform: uppercase;
  color: var(--color);
  z-index: 1;
}

.get-p-button::before,
.get-p-button::after {
  content: "";
  display: block;
  color: whitesmoke;
  width: 50px;
  height: 50px;
  transform: translate(-50%, -50%);
  position: absolute;
  border-radius: 50%;
  z-index: -1;
  background-color: #2771b2c5;
  transition: 1s ease;
}

.get-p-button::before {
  top: -1em;
  left: -1em;
}

.get-p-button::after {
  left: calc(100% + 1em);
  top: calc(100% + 1em);
}

.get-p-button:hover::before,
.get-p-button:hover::after {
  height: 410px;
  width: 410px;
}

.get-p-button:hover {
  color: rgb(222, 233, 237);
}

.get-p-button:active {
  filter: brightness(0.8);
}

/* button */
.button {
  --color: #0d4258;
  padding: 10px 30px;
  background-color: transparent;
  border-radius: 2.3em;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: 0.5s;
  font-weight: 400;
  font-size: 19px;
  background: #c6e9f8;
  border: 1px solid;
  font-family: inherit;
  text-transform: uppercase;
  color: var(--color);
  z-index: 1;
}

.button::before,
.button::after {
  content: "";
  display: block;
  color: whitesmoke;
  width: 50px;
  height: 50px;
  transform: translate(-50%, -50%);
  position: absolute;
  border-radius: 50%;
  z-index: -1;
  background-color: #2093ce;
  transition: 1s ease;
}

.button::before {
  top: -1em;
  left: -1em;
}

.button::after {
  left: calc(100% + 1em);
  top: calc(100% + 1em);
}

.button:hover::before,
.button:hover::after {
  height: 410px;
  width: 410px;
}

.button:hover {
  color: rgb(222, 233, 237);
}

.button:active {
  filter: brightness(0.8);
}
@media (max-width: 425px) {
  .custom .content .card {
    min-height: 200px;
    border-radius: 6px;
    background: #f0f8fe;
    box-shadow: 5px 5px 10px #a6a5b0, -5px -5px 10px #ffffff;
  }
  .main .heading h1 {
    font-size: 23px;
  }
  .main .content h3 {
    font-size: 20px;
  }
  .main .content p {
    color: black;
    font-size: 18px;
  }
  .analytics .content .card {
    min-height: 200px;
    border-radius: 6px;
    background: #f0f8fe;
    box-shadow: 5px 5px 10px #a6a5b0, -5px -5px 10px #ffffff;
  }
}

/*** Roadmap ***/
.roadmap-carousel {
  position: relative;
}

.roadmap-carousel::before {
  position: absolute;
  content: "";
  height: 0;
  width: 100%;
  top: 20px;
  left: 0;
  border-top: 2px dashed var(--secondary);
}

.roadmap-carousel .roadmap-item .roadmap-point {
  position: absolute;
  width: 30px;
  height: 30px;
  top: 6px;
  left: 50%;
  margin-left: -15px;
  background: #ffffff;
  border: 2px solid var(--primary);
  transform: rotate(45deg);
  display: flex;
  align-items: center;
  justify-content: center;
}

.roadmap-carousel .roadmap-item .roadmap-point span {
  display: block;
  width: 18px;
  height: 18px;
  background: var(--primary);
}

.roadmap-carousel .roadmap-item {
  position: relative;
  padding-top: 150px;
  text-align: center;
}

.roadmap-carousel .roadmap-item::before {
  position: absolute;
  content: "";
  width: 0;
  height: 115px;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  border-right: 2px dashed var(--secondary);
}

.roadmap-carousel .roadmap-item::after {
  position: absolute;
  content: "";
  width: 14px;
  height: 14px;
  top: 111px;
  left: 50%;
  margin-left: -7px;
  transform: rotate(45deg);
  background: var(--primary);
}

.roadmap-carousel .owl-nav {
  margin-top: 40px;
  display: flex;
  justify-content: center;
}

.roadmap-carousel .owl-nav .owl-prev,
.roadmap-carousel .owl-nav .owl-next {
  margin: 0 12px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  font-size: 22px;
  color: var(--light);
  background: var(--primary);
  transition: 0.5s;
}

.roadmap-carousel .owl-nav .owl-prev:hover,
.roadmap-carousel .owl-nav .owl-next:hover {
  color: var(--primary);
  background: var(--dark);
}

/* newsletter section start */

.shop_section {
  width: 100%;
  background-image: url(../img/home-img/background_banner.jpg);
  height: auto;
  padding: 90px 0px;
  background-size: cover;
  margin-top: 90px;
}

.shop_section .shop_taital {
  width: 100%;
  float: left;
  font-size: 40px;
  text-align: center;
  color: #f8f8f8;
}

.shop_section .shop_text {
  width: 100%;
  float: left;
  font-size: 16px;
  text-align: center;
  color: #f8f8f8;
  margin: 0px 0px 40px 0px;
}
@media (max-width: 390px) {
  .shop_section .shop_taital {
    font-size: 33px;
  }
}
/* newsletter section end */

/* ** Footer ** */

.footer .btn.btn-link {
  display: block;
  margin-bottom: 5px;
  padding: 0;
  text-align: left;
  color: #2d2d2d;
  font-weight: normal;
  text-transform: capitalize;
  transition: 0.3s;
}

.footer .btn.btn-link::before {
  position: relative;
  content: "\f105";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  color: var(--secondary);
  margin-right: 10px;
}

.footer .btn.btn-link:hover {
  color: var(--primary);
  letter-spacing: 1px;
  box-shadow: none;
}

.footer .btn.btn-square {
  color: var(--primary);
  border: 1px solid var(--primary);
}

.footer .btn.btn-square:hover {
  color: #ffffff;
  background: var(--primary);
}

.footer .copyright {
  padding: 25px 0;
  border-top: 1px solid rgba(17, 17, 17, 0.1);
}

.footer .copyright a {
  color: var(--primary);
}

.footer .copyright a:hover {
  color: var(--dark);
}
.footer p {
  color: #2d2d2d;
}
.footer .foot-span {
  color: #2d2d2d;
}
